import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/style/index.scss";
import "@/plugins/index.js";
import VueCookies from "vue-cookies";
import ElementUI from "element-ui";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-default/index.css";
// import "element-ui/lib/theme-chalk/display.css";
import { isFavoriteTopics } from "@/utils/favorite";


Vue.config.productionTip = false;
// 全局方法挂载
Vue.prototype.isFavoriteTopics = isFavoriteTopics;
//Vue.prototype.TcPlayer = TcPlayer;
//alert("TcPlayer:" + TcPlayer)

// Vue.use(ElementUI, {});
//import VueVideoPlayer from "vue-video-player";
//Vue.use(VueVideoPlayer);
Vue.use(ElementUI);
Vue.use(VueCookies);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
