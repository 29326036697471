import request from "@/utils/request";
const BASE_PATH = "/front/ground";

export function getFavoriteTopicList(params) {
  return request({
    url: BASE_PATH + "/favorite-topic/list",
    method: "get",
    params,
  });
}

export function addFavoriteTopics(id, data) {
  return request({
    url: BASE_PATH + "/favorite-topic/" + id,
    method: "post",
    data,
  });
}

export function removeFavoriteTopics(id) {
  return request({
    url: BASE_PATH + "/favorite-topic/" + id,
    method: "delete",
  });
}

export function getFavoriteTopicIDList() {
  return request({
    url: BASE_PATH + "/favorite-topic/idList",
    method: "get",
  });
}


