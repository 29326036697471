<template>
  <embed
    :src="src"
    :width="width"
    :height="height"
    type="image/svg+xml"
    pluginspage="http://www.adobe.com/svg/viewer/install/"
  />
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    src() {
      const svgMap = {};
      return svgMap[this.icon];
    },
  },
};
</script>
