<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss"></style>
<script>
export default {
  name: "Home",
  created() {
    this.$nextTick(() => {
      // 禁用右键
      //document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  },
};
</script>
