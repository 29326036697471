import Vue from "vue";
import Vuex from "vuex";
import {
  getCookiesByKey,
  setCookiesByKey,
  removeookiesByKey,
} from "@/utils/auth.js";
import { getFavoriteTopicIDList } from "@/api/ground.js";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    token: getCookiesByKey("cufehl.token"),
    favoriteTopicIds: [],
    session: "",
    isLogout: false,
  },
  mutations: {
    set_token(state, token) {
      state.token = token;
      state.session = token;
      state.isLogout = false;
      setCookiesByKey("cufehl.token", token);
    },
    del_token(state) {
      state.token = "";
      state.session = "";
      removeookiesByKey("cufehl.token");
    },
    get_token() {
      return this.state.session;
    },
    set_favoriteTopicIds(state, idList) {
      state.favoriteTopicIds = idList;
    },
  },
  actions: {
    searchAllTopicIdList({ commit, dispatch }) {
      commit("set_favoriteTopicIds", []);
      return new Promise((resolve, reject) => {
        getFavoriteTopicIDList()
          .then((res) => {
            commit("set_favoriteTopicIds", res.data);
            sessionStorage.setItem(
              "favoriteTopicIds",
              JSON.stringify(res.data)
            );
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
export default store;
