import request from "@/utils/request";

const BASE_PATH = "/front/member/";
export function getMemberProfile(query) {
  return request({
    url: BASE_PATH + "profile",
    method: "get",
    params: query,
  });
}

export function improveExtraInfo(data) {
  return request({
    url: BASE_PATH + "improve-extra-info",
    method: "put",
    data
  });
}

export function improveBaseInfo(data) {
  return request({
    url: BASE_PATH + "improve-base-info",
    method: "post",
    data,
  });
}
//课程
export function getMemberCourse(query) {
  return request({
    url: BASE_PATH + "course",
    method: "get",
    params: query,
  });
}
//过期课程
export function getMemberCourseExpired(query) {
  return request({
    url: BASE_PATH + "course/expired",
    method: "get",
    params: query,
  });
}
//学习情况
export function getMemberResource(query){
  return request({
    url: BASE_PATH + "resource",
    method: "get",
    params: query,
  });
}

export function uploadImages(data) {
  return request({
    url: "/front/client/upload/images",
    method: "post",
    data
  });
}

export function getMemberResourceStatus(query) {
  return request({
    url: BASE_PATH + "resource/status",
    method: "get",
    params: query,
  });
}

export function getExpiration(query) {
  return request({
    url: BASE_PATH + "order/expiration",
    method: "get",
    params: query,
  });
}