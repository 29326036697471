import {
  addFavoriteTopics,
  removeFavoriteTopics,
  getFavoriteTopicIDList,
} from "@/api/ground";
import store from "../store";
import Vue from "vue";

export function isFavoriteTopics(topicId) {
  let idList = sessionStorage.getItem("favoriteTopicIds") || "[]";
  let idArray = JSON.parse(idList);
  //console.log('favoriteTopicIds',idArray);
  return idArray.some((item) => item == topicId);
}

export async function addFavorite(topicId, data) {
  await addFavoriteTopics(topicId, data).then((res) => {
    if (res.code != 20000) {
      Vue.prototype.$baseMessage(res.msg, "error", "vab-hey-message-error");
    } else {
      Vue.prototype.$baseMessage(
        "收藏成功",
        "success",
        "vab-hey-message-success"
      );
      let idList = sessionStorage.getItem("favoriteTopicIds") || "[]";
      let idArray = JSON.parse(idList);
      idArray.push(topicId);
      sessionStorage.setItem("favoriteTopicIds", JSON.stringify(idArray));
    }
  });
}

export async function removeFavorite(topicId) {
  await removeFavoriteTopics(topicId).then((res) => {
    if (res.code != 20000) {
      Vue.prototype.$baseMessage(res.msg, "error", "vab-hey-message-error");
      return false;
    } else {
      Vue.prototype.$baseMessage(
        "取消成功",
        "success",
        "vab-hey-message-success"
      );
      let idList = sessionStorage.getItem("favoriteTopicIds") || "[]";
      let idArray = JSON.parse(idList);
      let index = idArray.indexOf(topicId);
      if (index >= 0) {
        idArray.splice(index, 1);
      }
      sessionStorage.setItem("favoriteTopicIds", JSON.stringify(idArray));
    }
  });
}
